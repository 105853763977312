<template>
  <b-modal
    id="modal-survey-result"
    title="Kết quả khảo sát"
    centered
    hide-footer
    size="xl"
    @hidden="resetModal"
    @show="onShowForm"
  >
    <div class="survey-result">
      <div class="w-100">
        <b-overlay :show="loading">
          <template v-for="section in sections">
            <CollapseQuestionSection
              :key="section.id"
              :id="section.id"
              :section="section"
              :patientId="patientId"
              :accountId="accountId"
              :ownPackage="ownPackage"
            />
          </template>
        </b-overlay>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalSurveyResult',
  components: {
    CollapseQuestionSection: () => import('./CollapseQuestionSection.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    patientId: {
      type: String,
      default: null,
    },
    accountId: {
      type: String,
      default: null,
    },
    ownPackage: {
      type: String,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sections: [],
      loading: false,
    };
  },
  mounted() {
    this.$root.$on('onShowForm-event', () => {
      this.onShowForm();
    });
  },
  methods: {
    resetModal() {
      this.sections = [];
    },

    onShowForm() {
      this.getServeyResult();
    },
    async getServeyResult() {
      try {
        this.loading = true;
        const { meta, data, error } = await this.$api.get(
          '/UserDashboard/Survey-detail',
          {
            params: {
              patientId: this.patientId,
              surveyId: this.id,
            },
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.sections = data.sections;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.survey-result {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
